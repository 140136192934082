import * as React from "react"

import Landing from "../components/landing/landing"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema, faqSectionSchema } from "../const/schemas"
import { graphql } from "gatsby"
import { languages } from "../const/languages"

const IndexPage = ({ data }) => <Landing data={data} lang={languages.EN} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Virtual try-on solutions on any screen you like!"
        description="AR-Labs.io solutions benefit your business with a tool that helps reduce return rates and increase sales by allowing customers to virtually try-on accessories in AR."
        canonicalURL="https://ar-labs.io"
      />
      <script type="application/ld+json">{organizationSchema}</script>
      <script type="application/ld+json">{faqSectionSchema}</script>
    </>
  )
}

export const BlogContentQuery = graphql`
  query BlogPageQuery {
    allWpPost {
      nodes {
        id
        title
        date
        categories {
          nodes {
            name
          }
        }
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        content
        author {
          node {
            name
            avatar {
              height
              width
              url
            }
          }
        }
      }
    }
  }
`
export default IndexPage
